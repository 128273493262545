import { accountingReportsApi } from '../base';
import { EAccountingReportTemplatesApiPaths } from '@/services/enums';
import { ReportTemplate } from './types';
import { ResponseI } from '../base/baseApi';

const reportTemplateApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getReportTemplates: builder.query<ResponseI<ReportTemplate>, any>({
      query: ({ page = 0, size = 10, sortKey, sortOrder, search, currentWorkspaceId }) => {
        return {
          url: `${EAccountingReportTemplatesApiPaths.REPORT_TEMPLATES}`,
          method: 'GET',
          params: {
            page,
            size,
            sortKey,
            sortOrder,
            search,
            workspaceId: currentWorkspaceId
          }
        };
      }
    })
  })
});

export const { useGetReportTemplatesQuery } = reportTemplateApi;
