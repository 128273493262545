import { CombinedTable, ReportTableRow, TColumnItem } from '@/components';
import { Invoice } from '@/services/api/payments/type';
import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

const columns: TColumnItem[] = [
  { label: 'Amount', accessor: 'amount', sortable: true },
  { label: 'Date', accessor: 'date', sortable: true }
];

type Props = {
  charges: Invoice[];
}

export const PreviousChargeTable: React.FC<Props> = ({ charges }) => {

  const rows = useMemo(() => {
    return charges.map(charge => {
      return (
        <ReportTableRow
          key={charge.id}
          item={charge as any}
          actionsList={[]}
        />
      );
    });
  }, [charges]);

  const TableToolbar = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        padding="1.25rem"
      >
        <Typography variant="h5">Previous charges</Typography>
      </Stack>
    );
  };

  return (
    <CombinedTable
      isLoading={false}
      isPaginationDisabled={true}
      paginate={false}
      columns={columns}
      rows={rows}
      TableToolbar={<TableToolbar />}
    />
  );
};
