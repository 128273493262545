import { Box, Button, MobileStepper, Paper, Typography } from '@mui/material';
import { PrimaryButton } from '../ui';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useState } from 'react';
import { PaymentCard } from './paymentCard';
import { PaymentMethodContent } from '@/services/api/payments/type';

interface CardCarouselProps {
  cards: PaymentMethodContent[];
}

export const PaymentMethod: React.FC<CardCarouselProps> = ({ cards }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cards.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (cards.length === 0) {
    return <div>No cards available</div>;
  }

  return (
    <>
      <Paper
        sx={{
          border: '1px solid #EAECF0',
          borderRadius: '12px',
          width: '100%',
          boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
          fontFamily: 'Manrope'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px 24px 20px 24px',
            borderBottom: '1px solid #EAECF0'
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '20px'
            }}
          >
            Payment method
          </Typography>
          <PrimaryButton
            label="Add new"
            startIcon={<AddOutlinedIcon fontSize="small" />}
          />
        </Box>
        <PaymentCard
          ownerName={cards[activeStep].ownerName}
          cardNumber={cards[activeStep].cardNumber}
          brand={cards[activeStep].brand}
          expirationDate={cards[activeStep].expirationDate}
          isDefault={cards[activeStep].isDefault}
        />
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{ mt: 2 }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
          }
        />
      </Paper>
    </>
  );
};
