import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SearchIcon } from '../icons';

// Replace with your actual publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

type Props = {
  selectedPlan: string;
  backAction: (showPlanSelection: boolean) => void;
};

const PaymentForm: React.FC<Props> = ({ selectedPlan, backAction }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage('');

    if (!stripe || !elements) {
      setErrorMessage('Stripe is not initialized yet. Please try again in a moment.');
      return;
    }

    setIsProcessing(true);

    try {
      // 1) Create PaymentMethod
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        throw new Error('Card number element not found.');
      }

      const { error: pmError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement
      });

      if (pmError) {
        throw new Error(pmError.message || 'Could not create payment method.');
      }
      if (!paymentMethod) {
        throw new Error('Payment method object was not returned.');
      }

      // 2) Send paymentMethod.id to your backend to create a subscription
      const response = await fetch('http://localhost:8080/api/v1/subscription/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Replace with your real auth token
          Authorization: `Bearer <YOUR_JWT_TOKEN>`
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          priceId: selectedPlan
        })
      });

      // Check for network or server error
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Subscription creation failed: ${text}`);
      }

      let serverResponse: {
        error?: string;
        clientSecret?: string;
      };

      try {
        serverResponse = await response.json();
      } catch (parseError) {
        throw new Error('Failed to parse server response.');
      }

      if (serverResponse.error) {
        throw new Error(serverResponse.error);
      }
      if (!serverResponse.clientSecret) {
        throw new Error('Server did not return a clientSecret for payment confirmation.');
      }

      // 3) Confirm the payment on the frontend
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        serverResponse.clientSecret,
        {
          ['payment_method']: {
            card: cardNumberElement
          }
        }
      );

      if (confirmError) {
        throw new Error(confirmError.message || 'Payment confirmation failed.');
      }
      if (!paymentIntent) {
        throw new Error('No PaymentIntent returned after confirmation.');
      }

      // Check final PaymentIntent status
      switch (paymentIntent.status) {
        case 'succeeded':
          alert('Payment succeeded!');
          // Handle post-payment success flow (e.g., redirect to success page)
          break;
        case 'requires_action':
          // Typically, Stripe automatically handles 3D Secure within confirmCardPayment,
          // but you can check here if additional steps are needed
          setErrorMessage(
            'Payment requires additional action. Please follow prompts on the screen.'
          );
          break;
        case 'processing':
          setErrorMessage('Payment is still processing. Please refresh or check again shortly.');
          break;
        default:
          setErrorMessage(`Payment status: ${paymentIntent.status}`);
          break;
      }
    } catch (err: any) {
      console.error('Payment flow error:', err);
      setErrorMessage(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#FAFAFA'
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: '400px',
          margin: '0 auto',
          borderRadius: '12px',
          boxShadow: '0 0 6px rgba(0,0,0,0.06)',
          borderColor: 'primary.main',
          borderWidth: 1,
          padding: '24px',
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '450px'
        }}
      >
        <img
          style={{
            marginTop: '12px'
          }}
          src="./assets/book-open-01.svg"
          alt=""
        />
        <h2 style={{ textAlign: 'center', padding: '0px', margin: '0px', marginTop: '36px' }}>
          Professional Plan
        </h2>
        <p style={{ textAlign: 'center', padding: '0px', margin: '0px', color: '#393A3D' }}>
          Please enter your payment details
        </p>

        <label style={{ width: '100%', marginTop: '1rem', color: '#393A3DA3', fontSize: '12px' }}>
          Card Number
          <div
            style={{
              display: 'flex',
              gap: '8px',
              marginTop: '6px'
            }}
          >
            <SearchIcon
              style={{
                width: '20px',
                height: '20px'
              }}
            />
            <div style={{ width: '100%' }}>
              <CardNumberElement
                options={{
                  style: { base: { fontSize: '16px', color: '#393A3DA3' } }
                }}
              />
            </div>
          </div>
          <hr style={{ backgroundColor: '#393A3DA3' }} />
        </label>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
            width: '100%',
            gap: '8px'
          }}
        >
          <label style={{ width: '100%', color: '#393A3DA3', fontSize: '12px' }}>
            Expiration Date
            <div
              style={{
                display: 'flex',
                gap: '8px',
                marginTop: '6px'
              }}
            >
              <SearchIcon
                style={{
                  width: '20px',
                  height: '20px'
                }}
              />
              <div style={{ width: '100%' }}>
                <CardExpiryElement
                  options={{
                    style: { base: { fontSize: '16px', color: '#393A3DA3' } }
                  }}
                />
              </div>
            </div>
            <hr style={{ backgroundColor: '#393A3DA3' }} />
          </label>

          <label style={{ width: '100%', color: '#393A3DA3', fontSize: '12px' }}>
            CVC
            <div
              style={{
                display: 'flex',
                gap: '8px',
                marginTop: '6px'
              }}
            >
              <SearchIcon
                style={{
                  width: '20px',
                  height: '20px'
                }}
              />
              <div style={{ width: '100%' }}>
                <CardCvcElement
                  options={{
                    style: { base: { fontSize: '16px', color: '#393A3DA3' } }
                  }}
                />
              </div>
            </div>
            <hr style={{ backgroundColor: '#393A3DA3' }} />
          </label>
        </div>

        <Button
          disabled={!stripe || isProcessing}
          variant="contained"
          color="primary"
          sx={{
            width: '100%',
            borderRadius: '12px',
            marginTop: '1rem',
            textTransform: 'none',
            padding: '6px 16px',
            fontWeight: 600,
            backgroundColor: '#0078C8',
            ':hover': {
              backgroundColor: '#0078C8'
            }
          }}
        >
          {isProcessing ? 'Processing...' : 'Subscribe & Pay'}
        </Button>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Button
          startIcon={<ArrowBackIcon />}
          disabled={!stripe || isProcessing}
          variant="contained"
          color="primary"
          sx={{
            marginTop: '1rem',
            width: '100%',
            borderRadius: '12px',
            textTransform: 'none',
            padding: '6px 16px',
            fontWeight: 600,
            backgroundColor: 'transparent',
            border: '1px solid #EAECF0',
            color: '#747A82',
            fontSize: '14px',
            lineHeight: '24px',
            ':hover': {
              backgroundColor: '#EAECF0'
            }
          }}
          onClick={() => {
            backAction(true);
          }}
        >
          Back
        </Button>
      </form>
    </div>
  );
};

const PaymentPage: React.FC<Props> = ({ selectedPlan, backAction }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm backAction={backAction} selectedPlan={selectedPlan} />
    </Elements>
  );
};

const styles: Record<string, React.CSSProperties> = {
  formWrapper: {
    width: '340px',
    padding: '1.5rem',
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  iconWrapper: {
    marginBottom: '1rem'
  },
  title: {
    margin: 0,
    fontSize: '1.25rem',
    fontWeight: 600
  },
  subtitle: {
    marginTop: '0.5rem',
    marginBottom: '1.5rem',
    fontSize: '0.95rem',
    color: '#767676',
    textAlign: 'center'
  },
  label: {
    display: 'block',
    width: '100%',
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#555',
    marginBottom: '1rem'
  },
  inputWrapper: {
    marginTop: '6px',
    padding: '0.5rem 0.75rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fafafa'
  },
  row: {
    display: 'flex',
    width: '100%'
  },
  submitButton: {
    width: '100%',
    marginTop: '1rem',

    color: '#fff',
    border: 'none',

    cursor: 'pointer',
    fontWeight: 600,

    borderRadius: '12px',
    textTransform: 'none',
    padding: '6px 16px',

    backgroundColor: '#0078C8'
  },
  backButton: {
    marginTop: '1rem',
    background: 'none',
    border: 'none',
    color: '#666',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '0.9rem'
  }
};

export default PaymentPage;
