import { Box, Button, Typography } from '@mui/material';
import paymentMethodIcon from '@/assets/icons/payment-method.svg';
import { PlanTag } from './planTag';
import { useState } from 'react';
import { EditCardModal } from '@/pages/account/editCardModal';

type Props = {
  ownerName: string;
  expirationDate: string;
  cardNumber: string;
  brand: string;
  isDefault: boolean;
}

export const PaymentCard: React.FC<Props> = ({ ownerName, expirationDate, cardNumber, brand, isDefault }) => {
  const [isOpenEditCardModal, setIsOpenEditCardModal] = useState(false);
  
  return (
    <>
      <Box
        sx={{
          padding: '24px 20px'
        }}
      >
        <Box
          sx={{
            border: '1px solid #EAECF0',
            borderRadius: '12px',
            padding: '20px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}
          >
            <img src={paymentMethodIcon} alt="paymentMethodIcon" />
            <Box
              sx={{
                display: 'flex',
                gap: '16px'
              }}
            >
              <Button
                onClick={() => setIsOpenEditCardModal(true)}
                sx={{
                  width: '116px',
                  height: '44px',
                  backgroundColor: '#D4D7DB52',
                  border: '1px solid #D4D7DB52'
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: '#393A3D',
                    fontWeight: '600',
                    fontSize: '14px'
                  }}
                >
                  Edit
                </Typography>
              </Button>
              <Button
                sx={{
                  width: '116px',
                  height: '44px',
                  backgroundColor: '#FFDADA',
                  border: '1px solid #FFBFBF'
                }}
              >
                <Typography
                  sx={{
                    textTransform: 'none',
                    color: '#FF6363',
                    fontWeight: '600',
                    fontSize: '14px'
                  }}
                >
                  Delete
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: '45px'
            }}
          >
            <PlanTag label={isDefault ? "Primary" : "Secondary"} style={{ backgroundColor: '#ebebeb' }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginTop: '12px'
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: '#151618',
                    fontSize: '20px',
                    fontWeight: '600'
                  }}
                >
                  {ownerName}
                </Typography>
                <Typography
                  sx={{
                    color: '#747A82',
                    fontSize: '14px',
                    fontWeight: '400',
                    marginTop: '10px'
                  }}
                >
                  **** **** **** {cardNumber}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: '#747A82',
                  fontSize: '14px',
                  fontWeight: '400'
                }}
              >
                Card expires at {expirationDate}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <EditCardModal isOpen={isOpenEditCardModal} onClose={() => setIsOpenEditCardModal(false)} />
    </>
  );
}