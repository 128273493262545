import React, { useState } from 'react';
import { SubscriptionPlans }from './subscriptionPlans';
import PaymentPage from './paymentPage';

export const SubscriptionFlow: React.FC = () => {
  const [showPlanSelection, setShowPlanSelection] = useState<boolean>(true);
  const [selectedPlan, setSelectedPlan] = useState<string>('basic');

  return (
    <div>
      {showPlanSelection ? (
        <SubscriptionPlans
          currentPlan={selectedPlan}
          onPlanSelect={(plan: string) => {
            setSelectedPlan(plan);
          }}
          onPlanApply={(plan: string) => {
            setSelectedPlan(plan);
            setShowPlanSelection(false);
          }}
        />
      ) : (
        <PaymentPage backAction={setShowPlanSelection} selectedPlan={selectedPlan}/>
      )}
    </div>
  );
};