import { accountingReportsApi } from '../base';
import { ECompanyPaths } from '@/services/enums';
import { ResponseI } from '../base/baseApi';
import { TGetPageParams } from '../reports/types';
import { UserCompanyResponceI } from './type';

const companyApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getCompanies: builder.query<ResponseI<UserCompanyResponceI>, TGetPageParams>({
      query: ({ page = 0, size = 10, sortKey, sortOrder, search, currentWorkspaceId }) => {
        return {
          url: `${ECompanyPaths.USER_COMPANIES}`,
          method: 'GET',
          params: {
            page,
            size,
            sortKey,
            sortOrder,
            search,
            workspaceId: currentWorkspaceId
          }
        };
      }
    }),
    deleteCompany: builder.mutation<void, { id?: string | number }>({
      query: ({ id }) => {
        return {
          url: `${ECompanyPaths.COMPANY}/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [{ type: 'REPORT_LIST' as const }] as any
    })
  })
});

export const { useGetCompaniesQuery, useDeleteCompanyMutation } = companyApi;
