import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { PlanTag } from './planTag';
import { useNavigate } from 'react-router-dom';

type Props = {
  name: string;
  price: number;
  features: string[];
  totalDays: number;
  daysLeft: number;
};

export const CurrentPlan: React.FC<Props> = ({ name, price, features, totalDays, daysLeft }) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        padding: '24px',
        border: '2px solid #0078C8',
        borderRadius: '12px',
        minWidth: '338px',
        boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
        fontFamily: 'Manrope'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <PlanTag label={name} />
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '18px'
            }}
          >
            $
          </Typography>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '34px',
              lineHeight: '18px',
              margin: '0 3px 0 5px'
            }}
          >
            {price}
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px'
            }}
          >
            /month
          </Typography>
        </Box>
      </Box>
      <List
        sx={{
          padding: '32px 0 20px 0'
        }}
      >
        {features.map((feature, idx) => 
          <ListItem
            key={idx}
            sx={{
              padding: '6px 0px'
            }}
          >
            <ListItemIcon sx={{ minWidth: '18px' }}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: '#C0C0C0',
                  borderRadius: '2px'
                }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                fontWeight: '400',
                fontSize: '16px'
              }}
              primary={feature}
            />
          </ListItem>
        )}
      </List>
      <Box
        sx={{
          marginBottom: '24px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: '500',
            fontSize: '14px'
          }}
        >
          <Typography>Days</Typography>
          <Typography>{totalDays - daysLeft} of {totalDays} days</Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={((totalDays - daysLeft) * 100) / totalDays}
          sx={{
            margin: '8px 0 6px 0',
            height: 8,
            borderRadius: 4,
            backgroundColor: '#e0e0e0', // Колір фону прогрес-бару
            '& .MuiLinearProgress-bar': {
              borderRadius: 4,
              backgroundColor: '#1976d2' // Колір заповненої частини
            }
          }}
        />
        <Typography
          sx={{
            color: '#828282',
            fontSize: '12px',
            fontWeight: '500'
          }}
        >
          {daysLeft} days remaining
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/subscription')}
        sx={{
          flexGrow: 1,
          padding: '8px 16px 8px 16px',
          textTransform: 'none',
          borderRadius: '12px',
          fontSize: '14px',
          color: '#0078C8',
          fontWeight: 600,
          backgroundColor: '#0078C829',
          width: '100%',
          '&:hover': { backgroundColor: '#0078C8', color: 'white' }
        }}
      >
        Upgrade plan
      </Button>
    </Paper>
  );
};
