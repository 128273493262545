import React, { useState } from 'react';
import {
  Box,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGetPlansQuery } from '@/services/api/payments';

type Props = {
  currentPlan: string;
  onPlanSelect: (plan: string) => void;
  onPlanApply: (plan: string) => void;
};

export const SubscriptionPlans: React.FC<Props> = ({
  currentPlan,
  onPlanSelect,
  onPlanApply
}: Props) => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('monthly');

  const { data } = useGetPlansQuery();
  const plans = data ?? [];

  const handleBillingCycleChange = (newCycle: 'monthly' | 'yearly' | null) => {
    if (newCycle) {
      setBillingCycle(newCycle);
    }
  };

  const PlanLabel = styled(Typography)(({ theme }) => ({
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(0.5, 1),
    fontSize: 12,
    fontWeight: 600
  }));

  return (
    <Box sx={{ py: 6, backgroundColor: '#fafafa', minHeight: '100vh' }}>
      <Container maxWidth="md">
        <Typography
          sx={{
            color: '#151618'
          }}
          variant="h4"
          align="center"
          fontWeight={600}
          gutterBottom
        >
          Connect your first company
        </Typography>
        <Typography
          sx={{
            color: '#151618',
            mb: 3
          }}
          variant="subtitle1"
          align="center"
          color="text.secondary"
        >
          Choose your passions your way. Whether you’d like to share your knowledge, experiences, or
          the latest news.
        </Typography>

        <Box display="flex" justifyContent="center" mb={4}>
          <Box
            display="flex"
            justifyContent="center"
            mb={2}
            sx={{
              backgroundColor: '#0078C833',
              padding: '4px',
              gap: 0,
              borderRadius: 999
            }}
          >
            <div
              style={{
                cursor: 'pointer',
                color: billingCycle === 'monthly' ? '#fff' : '#0078C8',
                fontWeight: billingCycle === 'monthly' ? 500 : 600,
                backgroundColor: billingCycle === 'monthly' ? '#0078C8' : 'transparent',
                padding: '8px 16px',
                borderRadius: 999
              }}
              onClick={() => handleBillingCycleChange('monthly')}
            >
              Monthly
            </div>
            <div
              style={{
                cursor: 'pointer',
                color: billingCycle === 'yearly' ? '#fff' : '#0078C8',
                fontWeight: billingCycle === 'yearly' ? 500 : 600,
                backgroundColor: billingCycle === 'yearly' ? '#0078C8' : 'transparent',
                padding: '8px 16px',
                borderRadius: 999
              }}
              onClick={() => handleBillingCycleChange('yearly')}
            >
              Yearly
            </div>
          </Box>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {Array.from(plans)
            .sort((a, b) => a.amount - b.amount)
            .map(plan => {
              if (plan.interval + 'ly' !== billingCycle) {
                return;
              }
              const isCurrentPlan = currentPlan === plan.id;

              return (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderRadius: '12px',
                      boxShadow: '0 0 6px rgba(0,0,0,0.06)',
                      borderColor: isCurrentPlan ? 'primary.main' : '#ddd',
                      borderWidth: isCurrentPlan ? 2 : 1,
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 4px 16px rgba(0,0,0,0.1)'
                      }
                    }}
                    onClick={() => onPlanSelect(plan.id)}
                  >
                    <CardContent sx={{ p: 3 }}>
                      <PlanLabel
                        sx={{
                          backgroundColor: isCurrentPlan ? 'rgba(25,118,210, 0.1)' : 'grey.100',
                          color: isCurrentPlan ? 'primary.main' : 'text.primary',
                          fontFamily: 'Manrope'
                        }}
                      >
                        {plan.name}
                      </PlanLabel>

                      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: 600,
                            marginRight: '4px'
                          }}
                        >
                          $
                        </span>
                        {plan.amount}{' '}
                        <Typography component="span" variant="body2" color="text.secondary">
                          / {billingCycle}
                        </Typography>
                      </Typography>

                      <List
                        sx={{
                          padding: '32px 0 20px 0',
                          gap:"8px"
                        }}
                      >
                        {plan.features.map((feature, idx) => (
                          <ListItem
                            key={`${plan.id}-feat-${idx}`}
                            sx={{
                              padding: '0px'
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: '18px' }}>
                              <Box
                                sx={{
                                  width: 8,
                                  height: 8,
                                  bgcolor: '#C0C0C0',
                                  borderRadius: '2px'
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                fontWeight: '400',
                                fontSize: '16px'
                              }}
                              primary={feature}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>

                    <CardActions sx={{ justifyContent: 'center', p: 3 }}>
                      <Button
                        onClick={() => onPlanApply(plan.id)}
                        variant="contained"
                        color="primary"
                        sx={{
                          width: '100%',
                          borderRadius: '12px',
                          textTransform: 'none',
                          padding: '6px 16px',
                          fontWeight: 600,
                          backgroundColor: '#0078C8'
                        }}
                      >
                        Subscribe and pay
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </Box>
  );
};
