export enum EAccountingReportsApiPaths {
  REPORTS = '/report'
}

export enum EReportTemplatePaths {
  REPORT_TEMPLATE = '/report-template'
}

export enum ECompanyPaths {
  USER_COMPANIES = '/company/UserCompanies',
  COMPANY = '/company'
}

export enum QuickBookPaths {
  QUICKBOOK_CONNECT = '/quickbooks/connectCompany',
  QUICKBOOK_LOGIN = '/quickbooks/login',
  QUICKBOOK_EXCHANGE_TOKEN = '/quickbooks/exchange-code'
}

export enum EAccountingReportTemplatesApiPaths {
  REPORT_TEMPLATES = '/report-template/getAllPaginated'
}

export enum EUserPaths {
  INFO = '/user/info',
  GOOGLE_CONNECT = '/google/connect',
  USER_BY_EMAIL = '/user/by-email'
}

export enum EWorspacesPaths {
  WORKSPACE = '/workspace',
  ASSUME_ROLE = '/workspace/assumeRole'
}

export enum EInvitationPaths {
  INVITATION = '/invitation',
  ACCEPT = '/invitation/accept'
}

export enum EPaymentsPaths {
  PLAN = '/plan',
  SUBSCRIPTION = '/subscription'
}
