import { accountingReportsApi } from '@/services';
import { EPaymentsPaths } from '@/services/enums';
import { CurrentPlanResponse, PlanResponse } from '@/services/api/payments/type';

const paymentApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getPlans: builder.query<PlanResponse[], void>({
      query: () => {
        return {
          url: `${EPaymentsPaths.PLAN}`,
          method: 'GET'
        };
      }
    }),
    getCurrentSubscription: builder.query<CurrentPlanResponse, void>({
      query: () => {
        return {
          url: `${EPaymentsPaths.SUBSCRIPTION}/current`,
          method: 'GET'
        };
      }
    })
  })
});

export const { useGetPlansQuery, useGetCurrentSubscriptionQuery  } = paymentApi;
