import { CurrentPlan } from '@/components/subscriptionsComponents/currentPlan';
import { PaymentMethod } from '@/components/subscriptionsComponents/paymentMethod';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { PreviousChargeTable } from './previousChargeTable';
import { useGetCurrentSubscriptionQuery } from '@/services/api/payments';

function Plan() {
  const theme = createTheme({
    typography: {
      fontFamily:
        'Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    }
  });

  const { data } = useGetCurrentSubscriptionQuery();

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '32px'
          }}
        >
          <CurrentPlan 
            name={data?.plan?.name ?? ''}
            price={data?.plan?.price ?? 0}
            features={data?.plan?.features ?? []}
            totalDays={data?.plan?.totalDays ?? 0}
            daysLeft={data?.plan?.daysLeft ?? 0}
          />
          <PaymentMethod cards={data?.paymentMethods ?? []}/>
        </Box>
        <Box
          sx={{
            marginTop: '32px'
          }}
        >
          <PreviousChargeTable charges={data?.invoices ?? []} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Plan;