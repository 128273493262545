import { accountingReportsApi } from '../base';
import { EAccountingReportsApiPaths } from '@/services/enums';
import { TGetPageParams, TGetReportsResponse, TReportBody } from './types';
import { ResponseI } from '../base/baseApi';

const reportsApi = accountingReportsApi.injectEndpoints({
  endpoints: builder => ({
    getReports: builder.query<ResponseI<TGetReportsResponse>, TGetPageParams>({
      query: ({ page, size = 10, sortKey, sortOrder, search, currentWorkspaceId }) => {
        return {
          url: `${EAccountingReportsApiPaths.REPORTS}/getAllPaginated`,
          method: 'GET',
          params: {
            page,
            size,
            sortKey,
            sortOrder,
            search,
            workspaceId: currentWorkspaceId
          }
        };
      },
      providesTags: (result): any => {
        if (!result?.data) {
          return ['REPORT'];
        }

        return result?.data?.content?.length
          ? [
              ...result.data.content.map(({ id }) => ({ type: 'REPORT' as const, id })),
              { type: 'REPORT_LIST' as const }
            ]
          : [{ type: 'REPORT_LIST' as const }];
      }
    }),
    deleteReport: builder.mutation<void, { id?: string | number }>({
      query: ({ id }) => {
        return {
          url: `${EAccountingReportsApiPaths.REPORTS}/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: [{ type: 'REPORT_LIST' as const }] as any
    }),
    postReport: builder.mutation<void, TReportBody>({
      query: body => {
        return {
          url: `${EAccountingReportsApiPaths.REPORTS}/create`,
          method: 'POST',
          body: body
        };
      },
      invalidatesTags: [{ type: 'REPORT_LIST' as const }] as any
    }),
    patchReport: builder.mutation<void, TReportBody>({
      query: body => {
        return {
          url: `${EAccountingReportsApiPaths.REPORTS}/${body.id}`,
          method: 'PATCH',
          body: body
        };
      },
      invalidatesTags: [{ type: 'REPORT_LIST' as const }] as any
    })
  })
});

export const {
  useGetReportsQuery,
  useDeleteReportMutation,
  usePostReportMutation,
  usePatchReportMutation
} = reportsApi;
