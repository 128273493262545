import { PrimaryButton } from '@/components';
import { useNavigate } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';

export const VerifyEmailSuccessPage = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: isMobile ? '0 24px' : '0 48px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: isMobile ? '24px' : '36px',
          fontSize: isMobile ? '16px' : '20px',
          padding: isMobile ? '24px' : '48px',
          borderRadius: '12px',
          boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.08)'
        }}
      >
        <img
          src="/assets/logo-full.svg"
          style={{
            width: isMobile ? '50%' : '30%'
          }}
          alt="Logo"
        />
        <div
          style={{
            fontSize: isMobile ? '24px' : '36px',
            fontWeight: '500'
          }}
        >
          Email verified!
        </div>
        <div
          style={{
            fontSize: isMobile ? '14px' : '16px',
            color: '#4F4F4F'
          }}
        >
          Thank you for verifying your email address. Your account has been activated and is ready
          to use.
        </div>
        <PrimaryButton
          label="Let's start"
          onClick={() => navigate('/signin')}
          endIcon={<ArrowForward />}
        />
      </div>
    </div>
  );
};
