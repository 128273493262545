import React, { FC } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { BaseTableHead, NoBaseTableResult } from './components';
import CircularProgress from '@mui/material/CircularProgress';
import { TableWithoutResultWrapper } from './baseTable.styled';
import { TBaseTableProps } from './baseTable.types';

const BaseTable: FC<TBaseTableProps> = ({
  rows,
  isLoading = false,
  tableProps,
  ...tableHeadProps
}) => {
  debugger; // eslint-disable-line no-debugger
  const contentMarkup = !!rows?.length && !isLoading && (
    <Table stickyHeader {...tableProps}>
      <BaseTableHead {...tableHeadProps} />
      <TableBody>{rows}</TableBody>
    </Table>
  );

  const noTableResultMarkup = !rows?.length && !isLoading && (
    <TableWithoutResultWrapper>
      <NoBaseTableResult title="No data" />
    </TableWithoutResultWrapper>
  );

  const loadingMarkup = isLoading && (
    <TableWithoutResultWrapper>
      <CircularProgress />
    </TableWithoutResultWrapper>
  );

  return (
    <TableContainer>
      {contentMarkup}
      {noTableResultMarkup}
      {loadingMarkup}
    </TableContainer>
  );
};

export default BaseTable;
