import TableRow from '@mui/material/TableRow';
import { FC } from 'react';
import { Typography } from '@mui/material';
import { ReportTableRowStyled } from './reportTableRow.styled';
import Stack from '@mui/material/Stack';
import { TReportTableRowProps } from './reportTableRow.types';
import { MoreButtonMenu } from '@/components';

const ReportTableRow: FC<TReportTableRowProps> = ({
  item,
  actionsMenuList,
  onTableRowClick,
  customCell
}) => {
  const { id, ...restReportData } = item;
  const tableCellsMarkup = Object.entries(restReportData).map(([key, value]) => {
    const onAction = () => onTableRowClick && onTableRowClick(id);
    if(customCell) {
      const findCustomCell = customCell.find(cellItem => cellItem.accessKey === key);
      if(findCustomCell) {
        return (
          <ReportTableRowStyled key={key} onClick={onAction} >
            {findCustomCell.render}
          </ReportTableRowStyled>
        );
      }
    }

    return (
      // sx={{ cursor: 'pointer' }}
      <ReportTableRowStyled key={key} onClick={onAction} >
        <Typography variant="subtitle2">{value || '——'}</Typography>
      </ReportTableRowStyled>
    );
  });

  return (
    <TableRow hover>
      {tableCellsMarkup}
      <ReportTableRowStyled>
        <Stack direction="row" spacing="1rem">
          {actionsMenuList && <MoreButtonMenu actions={actionsMenuList} />}
        </Stack>
      </ReportTableRowStyled>
    </TableRow>
  );
};

export default ReportTableRow;
