import { ReactNode } from 'react';
import { TReport } from '@/entity';

export type TCustomTableCell = {
  accessKey: string;
  render: ReactNode;
};

export type TReportTableRowProps = {
  item: TReport;
  actionsList: ReactNode[];
  actionsMenuList?: ReactNode[];
  onTableRowClick?: (id: number) => void;
  customCell?: TCustomTableCell[];
};

export enum EReportTableRowActions {
  EDIT = 'edit',
  DELETE = 'delete',
  DOWNLOAD_TEMPLATE = 'dewnload_template',
}
